<template>
<div style="width:100%">
<iframe :src="srcUrl" style="overflow:hidden;height: 100vh;width:100%" width="100%" frameBorder="0"></iframe>

<v-row v-if="items_archivos.length>0">
  <v-col cols="12">
    <b>ARCHIVOS</b>
  </v-col>
</v-row>
<v-row>
  <v-col cols="12" md="3" v-for="(item, index) in items_archivos" :key="index">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card>
          <v-img v-if="validarTipoImage(item)" height="150" width="100%" :src="rec_correos_srcImagen(item.id_correo, item.nombrearchivo)"></v-img>
          <center  v-else><v-icon size="150">mdi-paperclip</v-icon></center>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="12" md="2">
        <v-btn @click="downloadFile(item)" icon><v-icon>mdi-cloud-download</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="10">
        <v-list-item-title>{{ item.nombrearchivo }}</v-list-item-title>
      </v-col>
    </v-row>
  </v-col>
</v-row>

<br><br><br>
</div>
</template>

<script>
import recCorreos from '@/js/rec_correos.js'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'correo_frameComponent',
  components: {
  },
  mixins: [recCorreos, recFunciones],
  props: ['tokenCorreo', 'colores', 'id_proyecto', 'id_correo'],
  data: () => ({
    srcUrl: '',
    items_archivos: []
  }),
  created () {

  },
  watch: {
    tokenCorreo: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_correos_ver(this.id_correo)
      }
    }
  },
  methods: {
    validarTipoImage (item) {
      const img = this.rec_funciones_minusculas(item.extension)
      let isImg = false
      if (img === 'png' || img === 'jpg' || img === 'bpm' || img === 'gif' || img === 'tiff' || img === 'psd' || img === 'jpeg') {
        isImg = true
      }
      return isImg
    },
    downloadFile (item) {
      this.rec_correos_downloadFile(item.id_correo, item.nombrearchivo)
      // this.rec_correos_downloadFile(item.id_correo, item.nombrearchivo)
    }
  }

}
</script>
